import React, { useState, Fragment, useEffect } from 'react'
import { Row, Col, Button, Form, FormText } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/fontawesome-free-solid'
import {
  prefix,
  loginRedirect,
  notify,
} from '../../../utils/index_tagging_tool'
import {
  api
} from '../../../utils/index'

// Modifica del nome della metrica
function MetricName({ metric }) {
  // Rende visibile il textbox per cambiare il nome
  const [edit, setEdit] = useState(false)

  // Mantiene salvate le modifiche dell'utente
  const [name, setName] = useState(metric.name)

  // Salva a db le modifiche quando indicato dall'utente
  const saveNome = () => {
    if (!/^[a-zA-Z0-9_ ]{1,1024}$/.test(name)) {
      notify('Puoi inserire un nome composto solo di lettere (maiuscole o minuscole), numeri, spazi e trattini bassi', 'warning')
      return
    }

    metric.name = name

    api.put(`${prefix}custom-metrics/${metric.id}/`, metric)
      .then(loginRedirect)
      .then((data) => {
        if (typeof data === 'string') {
          notify('Esiste già una metrica con questo nome', 'warning')
          return
        }
        setEdit(false)
        notify('Nome modificato con successo', 'success')
      })
      .catch((e) => {
        notify('La comunicazione con il server è stata interrotta', 'error')
      })
  }

  useEffect(() => {
    // Aggiorna il nome quando cambia la lista (e quindi la metrica di questa riga)
    setName(metric.name)
  }, [metric.name])

  return (
    <>
      {edit ? (
        <>
          <input
            type="text"
            className="form-control"
            style={{ width: '80%', display: 'inline' }}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />{' '}
          <span
            style={{
              color: 'green',
              cursor: 'pointer',
            }}
            onClick={() => saveNome()}
          >
            ✔
          </span>
        </>
      ) : (
        <span>
            {name}
          {
            // Su richiesta di gabri (toschi) mostro il display name della colonna (quello che si vedrà poi su BQ o nel simula)
            ` (${metric.display_name}) `
          }
          <FontAwesomeIcon icon={faEdit} onClick={() => setEdit(true)} />
        </span>
      )}
    </>
  )
}

// Singolo elemento della lista, consente di operare sulla singola metrica e modificarla/cancellarla
function MetricRow({
                     metric,
                     reportName,
                     reFetch,
                   }) {
  // Apre il selettore per modificare il tipo
  const [editType, setEditType] = useState(false)

  // Stato dell'ultima operazione effettuata
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    // Resetto tutto allo stato iniziale per evitare problemi quando cambia l'indice (per aver aggiunto o rimosso una metrica)
    setError(false)
    setSuccess(false)
    setEditType(false)
  }, [metric])

  const handleRemove = (e) => {
    // Rimuovo la metrica, con popup che si accerta che tu sia sicuro, poi aggiorna la lista coerentemente
    e.preventDefault()

    // Conferma dell'utente
    const answer = window.confirm(
      'Sei sicuro? La metrica verrà rimossa definitivamente'
    )
    if (!answer) return

    let formData = new FormData()
    formData.append('displayName', metric.display_name)
    formData.append('reportName', reportName)

    api.delete(prefix + 'custom-metrics/', {
      data: formData,
    })
      .then(loginRedirect)
      .then((data) => {
        if (typeof data != 'number') {
          notify(
            'Qualcosa è andato storto, ricontrollare o contattare un innovation',
            'error'
          )
        } else {
          notify('Metrica rimossa con successo', 'success')
          reFetch(metric)
        }
      })
      .catch((error) => {
        console.log(error)
        notify(
          error.message,
          'error'
        )
      })
  }

  return (
    <Fragment>
      <Row>
        <Col xs={8} className={'text-left'}>
          {metric ? (
            <MetricName metric={metric} reportName={reportName} />
          ) : (
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          )}
        </Col>
        <Col xs={4} className={'text-center'}>
          <strong
            onClick={(e) => handleRemove(e)}
            style={{ cursor: 'pointer' }}
          >
            ❌
          </strong>
        </Col>
      </Row>
    </Fragment>
  )
}

// Bloccone che gestisce i linking e altre caratteristiche di una custom metr di un report
function MetricLinking({ metrics, savedMetrics, reportName, seo }) {
  const [loading, setLoading] = useState()

  // Un bellissimo force_component_update
  const reloadSelf = React.useReducer(() => ({}), {})[1]

  // Nome dato dall'utente e relativi errori/check sulla validità per dare feedback all'utente
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState('')
  const [nameValid, setNameValid] = useState('')

  // Selezione della metrica da aggiungere
  const [selectedSaved, setSelectedSaved] = useState(null)

  const evaluateName = () => {
    // Controlla che il nome sia valido, ovvero che non sia vuoto e che non abbia il nome di un'altra metriche
    setNameError('')
    setNameValid('')
    if (selectedSaved === null) {
      setNameError('Seleziona una metrica valida')
    }
    else if (!/^[a-zA-Z0-9_ ]{1,1024}$/.test(name))
      setNameError('Puoi inserire un nome composto solo di lettere (maiuscole o minuscole), numeri, spazi e trattini bassi')
    else if (
      metrics
        .filter((x) => x.display_name === savedMetrics.filter((x) => x.id === selectedSaved)[0].display_name).length > 0
    ) {
      setNameError(`La metrica ${savedMetrics.filter(x => x.id === selectedSaved)[0].display_name} è gia presente`)
    }
    else {
      setNameValid('La metrica selezionata è disponibile')
    }
  }
  // Aggiunge una metrica, in base ai parametri passati dall'utente (nome)
  const handleAdd = (e) => {
    e.preventDefault()

    if (selectedSaved === null) {
      setNameError('Seleziona una metrica valida')
      return
    }

    if (
      metrics
        .filter((x) => x.display_name === savedMetrics.filter((x) => x.id === selectedSaved)[0].display_name).length > 0
    ) {
      setNameError(`La metrica ${savedMetrics.filter(x => x.id === selectedSaved)[0].display_name} è gia presente`)
      return
    }

    if (!/^[a-zA-Z0-9_ ]{1,1024}$/.test(name)) {
      setNameError('Puoi inserire un nome composto solo di lettere (maiuscole o minuscole), numeri, spazi e trattini bassi')
      return
    }

    let formData = new FormData()
    formData.append('name', name)
    formData.append('reportName', reportName)

    // Aggiungo la saved solo se selezionata
    selectedSaved && formData.append('saved', selectedSaved)

    api.post(prefix + 'custom-metrics/', formData)
      .then(loginRedirect)
      .then((data) => {
        if (typeof data != 'object') {
          notify('Esiste già una metriche con questo nome', 'warning')
        } else {
          notify(`${name} aggiunta con successo`, 'success')
          metrics.push(data)
          document.getElementById('da_associare').value = 'Nuova' // Resetto la select
          setNameError('')
          setNameValid('')
          reloadSelf()
        }
      })
      .catch((error) => {
        console.log(error)
        notify(
          error.message,
          'error'
        )
      })
  }

  const handleSelect = (event) => {
    console.log(event)
    let target = event.target.value
    console.log(target)
    if (!target || target === 'Seleziona Metrica') {
      setSelectedSaved(null)
      setName('')
    }
    else {
      target = JSON.parse(target)
      setSelectedSaved(target.id)
      target = target.display_name.split(` (${target.display_name}`)[0]
      setName(target)
    }
  }

  const reFetch = (metric) => {
    // Usato quando viene cancellata una metrica, quindi cambia la lista di metriche
    metrics.splice(metrics.indexOf(metric), 1)
    reloadSelf()
  }

  return (
    <div className="Content-box mb-4">
      <h2 className="pb-3">Metriche</h2>
      <p className="pb-3">
        Da qui è possibile gestire le metriche del report,
        aggiungendole/rimuovendole.{' '}
      </p>
      {metrics.length > 0 && <Row className={'pb-3'}>
        <Col xs={8}>
          <label className={'text-left'} style={{fontSize: '1.2rem'}}>
            Name
          </label>
        </Col>
        <Col xs={4} className={'text-center'}>
          <label style={{fontSize: '1.2rem'}}>
            Remove
          </label>
        </Col>
      </Row>}
      <Form>
        {metrics.map((met, i) => (
          <MetricRow
            key={i}
            metric={met}
            savedMetrics={savedMetrics}
            reportName={reportName}
            reFetch={reFetch}
            metrics={metrics}
            i={i}
            seo={seo}
          />
        ))}
        <Row className={'mt-4'}>
          <Col xs={5}>
            <label htmlFor={'nome_nuova'}>Nome</label>
            <input
              id={'nome_nuova'}
              type="text"
              className="form-control"
              onBlur={evaluateName}
              value={name}
              onChange={(e) => {
                setNameValid('')
                setNameError('')
                setName(e.target.value)
              }}
            />
          </Col>
          <Col xs={5}>
            <label htmlFor={'da_associare'}>Metriche da associare</label>
            <Form.Control
              id={'da_associare'}
              defaultValue={null}
              as="select"
              onChange={handleSelect}
            >
              <option value={null} style={{ color: 'gray' }}>
                {'Seleziona Metrica'}
              </option>
              {savedMetrics.map((met, ix) => (
                <option key={ix} value={JSON.stringify(met)}>{met.name}</option>
              ))}
            </Form.Control>
          </Col>
          <Col xs={2} className="mt-auto">
            {loading ? (
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <Button
                type="submit"
                variant="warning"
                onClick={handleAdd}
                className="px-0"
                style={{ width: '100%' }}
              >
                Aggiungi
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={5}>
            {nameError !== '' && (
              <FormText
                style={{ color: 'red', fontWeight: 'bold' }}
                align={'left'}
              >
                {nameError}
              </FormText>
            )}
            {nameValid !== '' && (
              <FormText style={{ color: 'green' }} align={'left'}>
                {nameValid}
              </FormText>
            )}
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default MetricLinking
